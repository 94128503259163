<template>
  <div class="main-box">
    <div style="position: relative">
      <el-tabs v-model="tabIdx" @tab-click="chooseTab">
        <el-tab-pane label="全部" name="0"></el-tab-pane>
        <el-tab-pane label="已读" name="1"></el-tab-pane>
        <el-tab-pane label="未读" name="2"></el-tab-pane>
      </el-tabs>
      <span style="position: absolute; right: 0; top: 0;">
        <el-popconfirm
            title="确认全部标记成已读吗？"
            @confirm="readAll()"
        >
          <el-button slot="reference" type="text" size="small">全部已读</el-button>
      </el-popconfirm>
      </span>
    </div>
    <el-table
          :data="tableData"
          ref="rfTable"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange">
      <el-table-column
          prop="title"
          label="标题">
        <template slot-scope="scope">
          <div class="pd-tp-10 pd-bt-10 pt" @click="showConten(scope.row)">
            <span class="red" v-if="!scope.row.read_id">[未读]</span>
            {{scope.row.title}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="时间"
          align="center"
          width="240">
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
    <el-dialog
        title="消息详情"
        :visible.sync="dialogVisible"
        width="55%">
      <div v-html="checkItem.content"></div>
    </el-dialog>
  </div>
</template>
<script>

import {EventBus} from "@/utils/eventBus";

export default {
  components: {
  },
  data() {
    return {
      dialogVisible: false,
      checkItem: false,
      tabIdx: '0',
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    chooseTab(e) {
      if(e.index==1) {
        this.search.status = 1
      } else if(e.index==2) {
        this.search.status = 2
      } else {
        this.search.status = ''
      }
      this.handleSearch()
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status
      }
      this.$api.merchant.noticeSystemList(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    showConten(item) {
      if(!item.read_id) {
        this.changeStatus(item.id)
      }
      this.checkItem = item
      this.dialogVisible = true
    },
    changeStatus(id) {
      this.$api.merchant.noticeSystemRead({id: id},res => {
        if(res.errcode == 0) {
          this.getList()
          EventBus.$emit('updateNotice',{type:'notice'})
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    readAll() {
      this.$api.merchant.noticeSystemReadAll({},res => {
        if(res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
          EventBus.$emit('updateNotice',{type:'notice'})
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
